import React from "react";
import YouTube from "react-youtube";
import {useEffect } from "react";
import { useNavigate } from "react-router-dom";

const VideoScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "You're the one"
 }, []);

  return (
    <div className="container">
      <button
        onClick={() => navigate("/auth-options")}
        style={styles.backButton}
      >
        Back
      </button>
      <span style={{ padding: 10 }}></span>
      <YouTube videoId="fmdPdTvPlLM" opts={{ height: "450", width: "350" }} />
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    marginTop: "20px",
  },
  backButton: {
    backgroundColor: "#fc5858",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    fontSize: "25px",
  },
};

export default VideoScreen;
