import React from 'react';
import { BrowserRouter as Router, Route, Routes,  } from 'react-router-dom';
import SplashScreen from './components/SplashScreen';
import PasswordScreen from './components/PasswordScreen';
import VideoScreen from './components/VideoScreen';
import AuthOptionsScreen from './components/AuthOptionsScreen';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from '@vercel/speed-insights/react';


function App() {
  return (
    <AuthProvider >
      <Router>
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/auth-options" element={<AuthOptionsScreen />} />
          <Route path="/password" element={<PasswordScreen />} />
          <Route path="/video" element={<ProtectedRoute><VideoScreen /></ProtectedRoute>} />
        </Routes>
        <Analytics />
        <SpeedInsights />
      </Router>
    </AuthProvider>
  );
}

export default App;
