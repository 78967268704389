import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const PasswordScreen = () => {
  const [password, setPassword] = useState("");
  const [hintState, setHintState] = useState(0);
  const navigate = useNavigate();
  const { authenticate } = useAuth();

  useEffect(() => {
    document.title = "Enter Password"
 }, []);

  const handlePasswordSubmit = () => {
    if (password === "120223") {
      authenticate();
      navigate("/video");
    } else {
      alert("🤡 Wrong password smh 😔");
    }
    setPassword("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handlePasswordSubmit(); // Call the submit function on Enter key press
    }
  };

  const handleRevealHints = () => {
    setHintState((prev) => Math.min(prev + 1, 2));
  };

  return (
    <div className="container">
      <h1>Password 🔒</h1>
      <input
        type="password"
        placeholder="Enter password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={handleKeyPress}
        style={styles.input}
      />
      <span style={{ padding: 5 }}></span>
      <button onClick={handlePasswordSubmit} style={styles.button}>
        Submit ✔️
      </button>
      <span style={{ padding: 5 }}></span>
      {hintState < 2 && (
        <button onClick={handleRevealHints} style={styles.hintButton}>
          ❓ Reveal Hint ❓
        </button>
      )}
      {hintState >= 1 && <p style={{ fontSize: 20 }}>🤔 6 Numerical digits</p>}
      {hintState === 2 && (
        <p style={{ fontSize: 20 }}>😲 Official start of us</p>
      )}

      <button
        onClick={() => navigate("/auth-options")}
        style={styles.backButton}
      >
        Back
      </button>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
  },
  input: {
    height: "40px",
    width: "70vw",
    padding: "4px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "16px",
    boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1)", // Subtle shadow
    //transition: 'box-shadow 0.9s ease', // Smooth transition on focus
    backgroundColor: "LightPink",
    color: "white",
  },
  button: {
    backgroundColor: "#007AFF",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    fontSize: "25px",
  },
  hintButton: {
    backgroundColor: "#440a70",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    fontSize: "25px",
  },
  backButton: {
    backgroundColor: "#fc5858",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    marginTop: "30px",
    fontSize: "25px",
  },
};

export default PasswordScreen;
