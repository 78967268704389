import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useAuth } from "../context/AuthContext";

const AuthOptionsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // State for displaying error
  const [success, setSuccess] = useState(false); // State for displaying success message
  const navigate = useNavigate();
  const { authenticate } = useAuth();

  useEffect(() => {
    document.title = "Authenticate"
 }, []);
  
  // Hardcoded variable to check kiss permission
  const permitKiss = false;

  const handleKissPress = () => {
    setLoading(true);
    setError(""); // Reset error message before starting
    setSuccess(false); // Reset success state

    // Start "Analyzing Kiss" message and loading
    setTimeout(() => {
      if (permitKiss) {
        // After 4 seconds, show "Success!" message
        setSuccess(true);

        // After 1 more second, authenticate and navigate
        setTimeout(() => {
          setLoading(false);
          authenticate();
          navigate("/video"); // Navigate to VideoScreen if successful
        }, 1000); // Delay of 1 second for success message
      } else {
        setLoading(false);
        setError("Error: Kiss not found!"); // Set error message if kiss is not allowed
      }
    }, 4000); // Delay of 4 seconds for analyzing
  };

  return (
    <div style={styles.container}>
      <h1>⚠️Uh oh!⚠️</h1>
      <p style={{ fontSize: 25 }}>
        Looks like the content you're trying to access is <span style={{ color: "CadetBlue", fontWeight: "bold" }}>locked</span> 😭
      </p>
      <p style={{ fontSize: 25 }}>
        Select one of the following forms of authentication:
      </p>

      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {success ? (
            <p style={{ color: "#1aad41", fontSize: 20 }}>Success!</p>
          ) : (
            <>
              <p style={{ color: "#007AFF", fontSize: 20 }}>Analyzing Kiss</p>
              <ClipLoader size={50} color={"#007AFF"} loading={loading} />
            </>
          )}
        </div>
      ) : (
        <>
          <div style={styles.buttonRow}>
            <button onClick={handleKissPress} style={styles.kissButton}>
              Kiss 😘
            </button>
            <button
              onClick={() => navigate("/password")}
              style={styles.passwordButton}
            >
              Password 🔑
            </button>
          </div>
          {error && (
            <p style={{ color: "red", fontSize: 20, fontWeight: "bold" }}>
              {error}
            </p>
          )}{" "}
          {/* Display error message */}
          <div style={styles.backButtonContainer}>
            <button onClick={() => navigate("/")} style={styles.backButton}>
              Back
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    marginTop: "100px",
    padding: "5vw",
  },
  kissButton: {
    backgroundColor: "#f25edc",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    margin: "5px",
    fontSize: "25px",
  },
  passwordButton: {
    backgroundColor: "#007AFF",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    margin: "5px",
    fontSize: "25px",
  },
  backButton: {
    backgroundColor: "#fc5858",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    margin: "5px",
    fontSize: "25px",
  },
  buttonRow: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px", // Space between button row and back button
  },
  backButtonContainer: {
    textAlign: "center",
  },
};

export default AuthOptionsScreen;
